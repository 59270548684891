










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ProductPageStore } from '@elitepage/store/product-page'
import { CartStep, CartStore } from '~/store/cart'
import { ElitepageStore } from '@elitepage/store/elitepage'

const CartPaymentFormPaypalDirect = () => import('~/components/CartPaymentFormPaypalDirect.vue')

@Component({ components: { CartPaymentFormPaypalDirect } })
export default class ProductCheckoutPage extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']
    CartStep = CartStep

    @CartStore.State('cartStep') cartStep: number

    @ProductPageStore.State('productPage') productPage: IElitepage
    @ProductPageStore.State('product') product: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    @ProductPageStore.Action('loadProductPage') loadProductPage: (producerSlug: IProducer['slug']) => void
    @ProductPageStore.Action('loadProducts') loadProducts: (producerId: IProducer['id']) => void

    @Watch('product')
    productChanged(newValue: IProduct) {
        this.$store.commit('cart/RESET_CART_ITEMS', CartStep.PaymentOptions)
        this.$store.commit('cart/ADD_TO_CART', { product: newValue })
    }

    @Watch('cartStep')
    cartStepChanged(newValue: number) {
        if (this.cartStep === CartStep.Review) this.$router.back()
    }

    async mounted() {
        this.$store.commit('cart/SET_CART_STEP', CartStep.PaymentOptions)
        await this.loadProductPage(this.producerSlug)
        await this.loadProducts(this.producer.id)
    }
}
